import { AccordionModule } from 'primeng/accordion';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmationService, MessageService } from "primeng/api";
import { FormsModule } from "@angular/forms";
import { DndDirective } from "./directives/dnd.directive";
import { RippleModule } from "primeng/ripple";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";
import { NgxApexchartsModule } from "ngx-apexcharts";
import { ButtonModule } from "primeng/button";
import { DialogService } from "primeng/dynamicdialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { KeyFilterModule } from 'primeng/keyfilter';
import { AppDraggableDirective } from './directives/app-draggable.directive';
import { InputNumberModule } from 'primeng/inputnumber';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ImageDrawerDirective } from './directives/image-drawer.directive';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  declarations: [DndDirective, AppDraggableDirective,ImageDrawerDirective],
  imports: [CommonModule, FormsModule],
  providers: [MessageService, DialogService, ConfirmationService],
  exports: [
    FormsModule,
    DndDirective,
    AppDraggableDirective,
    RippleModule,
    TableModule,
    InputTextModule,
    NgxApexchartsModule,
    ButtonModule,
    RippleModule,
    DialogModule,
    ConfirmDialogModule,
    DropdownModule,
    KeyFilterModule,
    AccordionModule,
    DropdownModule,
    InputNumberModule,
    ContextMenuModule,
    ImageDrawerDirective,
    InputMaskModule
  ],
})
export class SharedModule {}
