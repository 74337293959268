import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import interact from "interactjs";

@Directive({
  selector: "[appDraggable]",
})
export class AppDraggableDirective implements AfterViewInit {
  @Output()
  dragEnd = new EventEmitter();

  @Input()
  dragEnabled: boolean;

  constructor(private elementRef: ElementRef) {}

  @Input({ required: true }) startPosition: { x: number; y: number };

  ngAfterViewInit(): void {
    if (this.dragEnabled) {
      let position = { ...this.startPosition }; // Copy the starting position
      this.elementRef.nativeElement.style.transform = `translate(${position.x}px, ${position.y}px)`;

      interact(this.elementRef.nativeElement).draggable({
        onmove: (event) => {
          position.x += +event.dx;
          position.y += +event.dy;
          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        },
        onend: (event) => {
          this.dragEnd.emit({
            x: position.x,
            y: position.y,
          });
        },
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "parent",
            endOnly:true,
          }),
        ],
      });
    }
  }
}
