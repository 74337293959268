import * as i0 from '@angular/core';
import { inject, NgZone, Component, ChangeDetectionStrategy, Input, ViewChild, NgModule } from '@angular/core';
import { asapScheduler } from 'rxjs';
const _c0 = ["chart"];
class ChartComponent {
  constructor() {
    this.ngZone = inject(NgZone);
    this.autoUpdateSeries = true;
    this.hasPendingLoad = false;
  }
  ngOnChanges(changes) {
    asapScheduler.schedule(() => {
      if (this.autoUpdateSeries && Object.keys(changes).filter(c => c !== "series").length === 0) {
        this.updateSeries(this.series, true);
        return;
      }
      this.createElement();
    });
  }
  ngOnDestroy() {
    this.destroy();
  }
  createElement() {
    // Do not run on server
    if (typeof window === 'undefined' || this.hasPendingLoad) {
      return;
    }
    this.hasPendingLoad = true;
    this.ngZone.runOutsideAngular(async () => {
      this.destroy();
      const ApexCharts = (await import('apexcharts')).default;
      const options = this.buildOptions();
      this.chartObj = new ApexCharts(this.chartElement.nativeElement, options);
      window.ApexCharts = ApexCharts;
      await this.render();
      this.hasPendingLoad = false;
    });
  }
  render() {
    return this.ngZone.runOutsideAngular(() => this.chartObj?.render());
  }
  updateOptions(options, redrawPaths, animate, updateSyncedCharts) {
    return this.ngZone.runOutsideAngular(() => this.chartObj?.updateOptions(options, redrawPaths, animate, updateSyncedCharts));
  }
  updateSeries(newSeries, animate) {
    return this.ngZone.runOutsideAngular(() => this.chartObj?.updateSeries(newSeries, animate));
  }
  appendSeries(newSeries, animate) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.appendSeries(newSeries, animate));
  }
  appendData(newData) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.appendData(newData));
  }
  toggleSeries(seriesName) {
    return this.ngZone.runOutsideAngular(() => this.chartObj?.toggleSeries(seriesName));
  }
  showSeries(seriesName) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.showSeries(seriesName));
  }
  hideSeries(seriesName) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.hideSeries(seriesName));
  }
  resetSeries() {
    this.ngZone.runOutsideAngular(() => this.chartObj?.resetSeries());
  }
  zoomX(min, max) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.zoomX(min, max));
  }
  toggleDataPointSelection(seriesIndex, dataPointIndex) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.toggleDataPointSelection(seriesIndex, dataPointIndex));
  }
  destroy() {
    this.chartObj?.destroy();
  }
  setLocale(localeName) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.setLocale(localeName));
  }
  paper() {
    this.ngZone.runOutsideAngular(() => this.chartObj?.paper());
  }
  addXaxisAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.addXaxisAnnotation(options, pushToMemory, context));
  }
  addYaxisAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.addYaxisAnnotation(options, pushToMemory, context));
  }
  addPointAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.addPointAnnotation(options, pushToMemory, context));
  }
  removeAnnotation(id, options) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.removeAnnotation(id, options));
  }
  clearAnnotations(options) {
    this.ngZone.runOutsideAngular(() => this.chartObj?.clearAnnotations(options));
  }
  dataURI(options) {
    return this.chartObj?.dataURI(options);
  }
  buildOptions() {
    const options = {};
    if (this.annotations) {
      options.annotations = this.annotations;
    }
    if (this.chart) {
      options.chart = this.chart;
    }
    if (this.colors) {
      options.colors = this.colors;
    }
    if (this.dataLabels) {
      options.dataLabels = this.dataLabels;
    }
    if (this.series) {
      options.series = this.series;
    }
    if (this.stroke) {
      options.stroke = this.stroke;
    }
    if (this.labels) {
      options.labels = this.labels;
    }
    if (this.legend) {
      options.legend = this.legend;
    }
    if (this.fill) {
      options.fill = this.fill;
    }
    if (this.tooltip) {
      options.tooltip = this.tooltip;
    }
    if (this.plotOptions) {
      options.plotOptions = this.plotOptions;
    }
    if (this.responsive) {
      options.responsive = this.responsive;
    }
    if (this.markers) {
      options.markers = this.markers;
    }
    if (this.noData) {
      options.noData = this.noData;
    }
    if (this.xaxis) {
      options.xaxis = this.xaxis;
    }
    if (this.yaxis) {
      options.yaxis = this.yaxis;
    }
    if (this.forecastDataPoints) {
      options.forecastDataPoints = this.forecastDataPoints;
    }
    if (this.grid) {
      options.grid = this.grid;
    }
    if (this.states) {
      options.states = this.states;
    }
    if (this.title) {
      options.title = this.title;
    }
    if (this.subtitle) {
      options.subtitle = this.subtitle;
    }
    if (this.theme) {
      options.theme = this.theme;
    }
    return options;
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ChartComponent_Factory(t) {
      return new (t || ChartComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ChartComponent,
      selectors: [["apx-chart"]],
      viewQuery: function ChartComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.chartElement = _t.first);
        }
      },
      inputs: {
        chart: "chart",
        annotations: "annotations",
        colors: "colors",
        dataLabels: "dataLabels",
        series: "series",
        stroke: "stroke",
        labels: "labels",
        legend: "legend",
        markers: "markers",
        noData: "noData",
        fill: "fill",
        tooltip: "tooltip",
        plotOptions: "plotOptions",
        responsive: "responsive",
        xaxis: "xaxis",
        yaxis: "yaxis",
        forecastDataPoints: "forecastDataPoints",
        grid: "grid",
        states: "states",
        title: "title",
        subtitle: "subtitle",
        theme: "theme",
        autoUpdateSeries: "autoUpdateSeries"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 0,
      consts: [["chart", ""]],
      template: function ChartComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChartComponent, [{
    type: Component,
    args: [{
      selector: 'apx-chart',
      template: '<div #chart></div>',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    chart: [{
      type: Input
    }],
    annotations: [{
      type: Input
    }],
    colors: [{
      type: Input
    }],
    dataLabels: [{
      type: Input
    }],
    series: [{
      type: Input
    }],
    stroke: [{
      type: Input
    }],
    labels: [{
      type: Input
    }],
    legend: [{
      type: Input
    }],
    markers: [{
      type: Input
    }],
    noData: [{
      type: Input
    }],
    fill: [{
      type: Input
    }],
    tooltip: [{
      type: Input
    }],
    plotOptions: [{
      type: Input
    }],
    responsive: [{
      type: Input
    }],
    xaxis: [{
      type: Input
    }],
    yaxis: [{
      type: Input
    }],
    forecastDataPoints: [{
      type: Input
    }],
    grid: [{
      type: Input
    }],
    states: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    subtitle: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    autoUpdateSeries: [{
      type: Input
    }],
    chartElement: [{
      type: ViewChild,
      args: ['chart', {
        static: true
      }]
    }]
  });
})();
class NgxApexchartsModule {
  /** @nocollapse */static {
    this.ɵfac = function NgxApexchartsModule_Factory(t) {
      return new (t || NgxApexchartsModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxApexchartsModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxApexchartsModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [ChartComponent],
      exports: [ChartComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-apexcharts
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ChartComponent, NgxApexchartsModule };
